import {
  CardsCampaigns,
  DefaultLayout,
  CardPhoneOptions,
  CarouselPress,
  CarouselRecycling,
} from "@/components";

import {
  AppStoreBadge,
  landingInicioResiduos,
  landingUneteReciclaGana,
  playStoreBadge,
} from "@/assets";
import Link from "next/link";
import { app_home_url, CDN_URL } from "@/constants";
import { GetStaticProps } from "next";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { ContactForm } from "@/components/organisms";
import { StickyFlextival } from "@/components/atoms/StickyFlextival/StickyFlextival";

export default function Home() {
  const t = useTranslations();
  return (
    <>
      <section
        className="grid grid-cols-12 grid-flow-row p-7 w-full bg-[#41b59a] rounded-br-[150px] lg:rounded-br-[400px] lg:pl-[5%] mt-16"
        style={{
          backgroundImage: `url(${
            CDN_URL + "/assets_web/images/landing/1-waveblanca-01.svg"
          }), url(${
            CDN_URL + "/assets_web/images/landing/2-waveblanca-01.svg"
          }), url(${
            CDN_URL + "/assets_web/images/landing/3-waveblanca-01.svg"
          })`,
          backgroundSize: "8%, 11%, 11%, 55%, 59%, 10%, 50px",
          backgroundRepeat:
            "no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,no-repeat, repeat",
          backgroundPosition: "0% 0%, -1% 95%, 100% 9%, center bottom",
        }}
      >
        <div className="col-span-10 lg:col-span-6 col-start-2 lg:col-start-1 lg:row-start-2 order-1 flex justify-start">
          <Image
            src={landingUneteReciclaGana}
            alt=""
            className="p-0 lg:p-3"
            priority
            width={1100}
            height={618}
            sizes="100vw"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </div>
        <div className="col-span-12 lg:col-span-5 lg:row-span-5 order-3 lg:order-2 lg:col-start-8 flex justify-center items-center">
          <Image
            src={landingInicioResiduos}
            alt=""
            width={800}
            height={800}
            priority
            sizes="100vw"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </div>
        <div className="col-span-12 order-2 lg:order-3 lg:col-span-7 lg:row-span-2 flex items-center">
          <p className="text-left text-md lg:text-xl my-3 lg:my-4 mx-3 text-white">
            {t("campaignDescription")}
          </p>
        </div>
        <div className="col-span-12 lg:col-span-5 order-4  flex flex-wrap align-middle justify-around w-full p-1 pt-12 lg:pt-1">
          <div className="flex w-1/3 lg:w-5/12 justify-center items-center">
            <Link href={app_home_url} prefetch={false}>
              <Image
                className="w-full max-w-52"
                src={playStoreBadge}
                alt="Badge PlayStore para Ecolana"
                priority
                sizes="100vw"
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </Link>
          </div>
          <div className="flex w-1/3 lg:w-5/12 justify-center items-center">
            <Link href={app_home_url} prefetch={false}>
              <AppStoreBadge className="w-full max-w-52" />
            </Link>
          </div>
        </div>
      </section>
      <CardsCampaigns />
      <CardPhoneOptions />
      <CarouselRecycling />
      <CarouselPress />
      <ContactForm origin="home" />
      <StickyFlextival position="sticky" />
    </>
  );
}

Home.getLayout = function getLayout(page: React.ReactNode) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export const getStaticProps: GetStaticProps = async (context) => {
  return {
    props: {
      messages: (await import(`@/messages/${context.locale}.json`)).default,
    },
  };
};
