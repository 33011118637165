import { CircleX } from "lucide-react";
import React, { FC, useEffect, useState } from "react";
import styles from "./StickyFlextival.module.css";

interface Props {
  position?: "sticky" | "absolute";
}
import { useSessionStorage } from "usehooks-ts";
import { Button } from "../shadcn/ui/button";
import { flex_lineas_blancas } from "@/assets";
import Image, { StaticImageData } from "next/image";
import { BabyGirl, GothamRounded } from "@/styles/fonts";

export const StickyFlextival: FC<Props> = ({ position }) => {
  const [showBanner, setShowBanner] = useState(false);
  const [value, setValue] = useSessionStorage(
    "show-banner-flextival-nov-2024",
    1
  );
  useEffect(() => {
    setTimeout(() => {
      if (value === 0) {
        return;
      }
      setShowBanner(true);
    }, 1000);
  }, []);
  return (
    <section
      className={styles.main}
      style={{
        position: position,
        opacity: showBanner ? 1 : 0,
        height: showBanner ? "auto" : 0,
      }}
    >
      <div className={styles.container_sticky}>
        <CircleX
          className={styles.close_banner_flextival}
          onClick={() => {
            setValue(0);
            setShowBanner(false);
          }}
        />
        <div className="p-0 md:p-3 py-2 text-center grid grid-cols-1 gap-3 lg:grid-cols-5 items-center justify-center">
          <div className="px-3 md:px-2 grid grid-cols-2 md:grid-cols-1 justify-center items-center">
            <Image
              src="/images/flextival/texto_flextival.webp"
              alt="Flextival_letras"
              width={640}
              height={580}
              style={{ objectFit: "contain" }}
            />
            <Image
              src="/images/flextival/ecolana_Flex_7.webp"
              alt="Flextival_logo"
              width={640}
              height={580}
              className="w-[80%] justify-self-center"
              style={{ objectFit: "contain" }}
            />
          </div>
          <div className="relative flex flex-col justify-center text-center items-center w-full justify-self-center">
            <p
              className="text-xl md:text-xl font-bold text-[#FFF]"
              style={{ textShadow: "0px 2px 2px #196C6C" }}
            >
              EL EVENTO PARA RECICLAR
            </p>
            <p className="text-lg md:text-xl text-[#FFF]">
              TODAS TUS BOLSAS DE PLÁSTICO
            </p>
          </div>
          <div
            className={`px-3 md:px-2 grid grid-cols-4 h-fit gap-2 justify-center text-center items-center w-full md:col-span-2`}
          >
            <div
              className="rounded-xl bg-[#FFD251] text-[#1557E4] flex flex-col items-center justify-center py-2"
              style={{ boxShadow: "4px 4px 0px 0px #E0840C" }}
            >
              <p className="text-lg font-bold">MARZO</p>
              <div className="text-xxs md:text-base flex flex-row space-x-1">
                <p className="font-bold">
                  10:00 <span className="text-xxs">AM</span>
                </p>
                <p className="font-bold">
                  2:00 <span className="text-xxs">PM</span>
                </p>
              </div>
            </div>
            <div
              className="col-span-3 grid grid-cols-4 items-center justify-center rounded-xl bg-[#1557E4] py-2 h-full md:h-fit"
              style={{ boxShadow: "4px 4px 0px 0px #05499F" }}
            >
              <p className="text-[#92F857] text-2xl md:text-3xl font-bold">29</p>
              <div className="col-span-3 text-white text-center">
                <p className="text-xs lg:text-lg font-bold">PARQUE LÁZARO CÁRDENAS</p>
                <p className="text-xxs md:text-base">ALCALDÍA CUAUHTÉMOC</p>
              </div>
            </div>
            <div
              className="rounded-xl h-full flex items-center justify-center bg-[#A9ECDE] text-[#1557E4]  lg:text-2xl xl:text-3xl font-bold"
              style={{ boxShadow: "4px 4px 0px 0px #6CDDC0" }}
            >
              <p>CDMX</p>
            </div>
            <div
              className=" col-span-3 grid grid-cols-4 items-center justify-center rounded-xl bg-[#1557E4] py-2 h-full"
              style={{ boxShadow: "4px 4px 0px 0px #05499F" }}
            >
              <p className="text-[#92F857] text-2xl md:text-3xl font-bold">30</p>
              <div className="col-span-3 text-white text-center">
                <p className="text-xs lg:text-lg font-bold">PARQUE DE LOS VENADOS</p>
                <p className="text-xxs md:text-base">ALCALDÍA BENITO JUÁREZ</p>
              </div>
            </div>
          </div>
          <div
            className="lg:col-span-1 md:col-span-2 col-span-1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className="bg-[#FFD251] rounded-3xl text-[#1557E4] text-xl"
              style={{ boxShadow: "4px 4px 0px 0px #E0840C" }}
              onClick={() => {
                window.location.href = "/flextival";
              }}
            >
              Conoce más
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
